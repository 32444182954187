import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    meta: { layout: "empty" },
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/",
    name: "orders",
    meta: { layout: "base", requiresAuth: true },
    component: () => import("../views/Orders.vue"),
  },
  {
    path: "/orders/:id",
    name: "order-details",
    meta: { layout: "base", requiresAuth: true },
    component: () => import("../views/OrderDetails.vue"),
  },
  {
    path: "/orders/:id/vehicle",
    name: "order-details-auto",
    meta: { layout: "base", requiresAuth: true },
    component: () => import("../views/OrderDetailsVehicle.vue"),
  },
  {
    path: "/offers/:id",
    name: "offer-details",
    meta: { layout: "base", requiresAuth: true },
    component: () => import("../views/OfferDetails.vue"),
  },
  {
    path: "/offers",
    name: "offers",
    meta: { layout: "base", requiresAuth: true },
    component: () => import("../views/Offers.vue"),
  },
  {
    path: "/profile",
    name: "profile",
    meta: { layout: "base", requiresAuth: true },
    component: () => import("../views/Profile.vue"),
  },
  {
    path: "/chat-admin",
    name: "chat-admin",
    meta: { layout: "base", requiresAuth: true },
    component: () => import("../views/ChatAdmin.vue"),
  },
  {
    path: "/chats",
    name: "chats",
    meta: { layout: "base", requiresAuth: true },
    component: () => import("../views/Chats.vue"),
  },
  {
    path: "/chats/:id",
    name: "chat-offer",
    meta: { layout: "base", requiresAuth: true },
    component: () => import("../views/ChatOffer.vue"),
  },
  {
    path: "/payemnt/succeed",
    beforeEnter: (to, from, next) => {
      next({ path: "/profile", query: { paymentSuccess: true } });
    },
  },
  {
    path: "/payment/success",
    beforeEnter: (to, from, next) => {
      next({ path: "/profile", query: { paymentSuccess: true } });
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

export default router;
