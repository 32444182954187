export default {
  actions: {
    async fetchRegions(ctx) {
      const res = await fetch("https://vp.bw2api.ru/api/v1/complement/region");
      const regions = await res.json();

      ctx.commit("updateRegions", regions);
    },
    async fetchLiveRegions(ctx) {
      const res = await fetch(
        "https://vp.bw2api.ru/api/v1/complement/region/live"
      );
      const liveRegions = await res.json();

      ctx.commit("updateLiveRegions", liveRegions);
    },
  },
  mutations: {
    updateRegions(state, regions) {
      state.regions = regions;
    },
    updateLiveRegions(state, liveRegions) {
      state.liveRegions = liveRegions;
    },
  },
  state: {
    regions: [],
    liveRegions: [],
  },
  getters: {
    regions(state) {
      return state.regions;
    },
    liveRegions(state) {
      return state.liveRegions;
    },
  },
};
