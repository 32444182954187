import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import store from "./store";
import VueMoment from "vue-moment";
import Axios from "axios";
import VueMask from "v-mask";
import { Icon } from "leaflet";
import AudioRecorder from "vue-audio-recorder";
import OneSignalVue from "onesignal-vue";

Vue.use(OneSignalVue);
Vue.use(AudioRecorder);
Vue.use(VueMask);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

Vue.prototype.$http = Axios;

const token = localStorage.getItem("token");

if (token) {
  Vue.prototype.$http.defaults.headers.common["Authorization"] = token;
}

Vue.config.productionTip = false;

Vue.use(VueMoment);

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
