import axios from "axios";

export default {
  actions: {
    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        axios({
          method: "POST",
          url: "https://vp.bw2api.ru/api/v1/enter/password",
          data: user,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then((resp) => {
            const token = "Bearer " + resp.data.token;
            const user_auth = resp.data.data["auth_id"];
            localStorage.setItem("token", token);
            localStorage.setItem("user_auth", user_auth);
            axios.defaults.headers.common["Authorization"] = token;
            commit("auth_success", token, user_auth);
            resolve(resp);
          })
          .catch((err) => {
            commit("auth_error");
            localStorage.removeItem("token");
            localStorage.removeItem("user_auth");
            reject(err);
          });
      });
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        commit("logout");
        localStorage.removeItem("token");
        localStorage.removeItem("user_auth");
        delete axios.defaults.headers.common["Authorization"];
        resolve();
      });
    },
  },
  mutations: {
    auth_request(state) {
      state.status = "loading";
    },
    auth_success(state, token) {
      state.status = "success";
      state.token = token;
    },
    auth_error(state) {
      state.status = "error";
    },
    logout(state) {
      state.status = "";
      state.token = "";
    },
  },
  state: {
    status: "",
    token: localStorage.getItem("token") || "",
  },
  getters: {
    isLoggedIn: (state) => !!state.token,
    authStatus: (state) => state.status,
  },
};
