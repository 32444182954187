export default {
  actions: {
    async fetchOffersReservedCount(ctx, profile) {
      const params = new URLSearchParams({
        creator: profile._id,
      });

      const res = await fetch(
        "https://vp.bw2api.ru/api/v1/offer/reserved?" + params.toString()
      );
      const offersCountReserved = await res.json();

      if (ctx.state.offersCountReserved == null) {
        ctx.commit("updateOffersCountReserved", offersCountReserved);
      } else if (ctx.state.offersCountReserved < offersCountReserved) {
        ctx.commit("updateOffersCountReserved", offersCountReserved);
      }
    },

    async fetchOffers(ctx, profile) {
      const params = new URLSearchParams({
        creator: profile["_id"],
        _populate: "order order.vehicle",
        _sort: "-updatedAt",
      });

      const res = await fetch(
        "https://vp.bw2api.ru/api/v1/crud/offer?" + params.toString()
      );
      const offers = await res.json();

      ctx.commit("updateOffers", offers);
    },
    async fetchOffer({}, id) {
      const params = new URLSearchParams({
        _id: id,
        _populate: "order order.vehicle",
      });

      const res = await fetch(
        "https://vp.bw2api.ru/api/v1/crud/offer?" + params.toString()
      );

      return (await res.json())[0];
    },
  },
  mutations: {
    updateOffers(state, offers) {
      state.offers = offers;
    },
    updateOffersCountReserved(state, count) {
      state.offersCountReserved = count;
    },
  },
  state: {
    offers: [],
    offersCountReserved: null,
  },
  getters: {
    allOffers(state) {
      return state.offers;
    },
    offersCountReserved(state) {
      return state.offersCountReserved;
    },
  },
};
