// todo переделать (использовать функцию по другому, не в store)

import axios from "axios";

export default {
  actions: {
    async sendMessage(ctx, obj) {
      let formdata = new FormData();
      formdata.append("chat", obj.chatId);
      formdata.append("text", obj.text);

      try {
        let res = (
          await axios.post(`https://vp.bw2api.ru/api/v1/chat/message`, formdata)
        ).data;
        return res;
      } catch (error) {
        console.log(error);
      }
    },
  },
  mutations: {},
  state: {},
  getters: {},
};
