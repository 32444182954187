<template>
  <div v-if="dataLoaded">
    <Navbar />
    <audio
      class="d-none"
      id="audio-new-order"
      src="@/assets/audio/new-order.mp3"
    ></audio>
    <audio
      class="d-none"
      id="audio-new-offer"
      src="@/assets/audio/new-offer.mp3"
    ></audio>
    <router-view />
  </div>
</template>

<script>
import Navbar from "../components/Navbar";
import { mapActions, mapGetters, mapMutations } from "vuex";

let socket;

export default {
  name: "base-layout",
  data() {
    return {
      dataLoaded: false,
      orderCountTimeId: "",
      offersReservedCountTimeId: "",
    };
  },
  components: {
    Navbar,
  },
  computed: {
    ...mapGetters([
      "profile",
      "savedProfile",
      "allOrdersCount",
      "offersCountReserved",
    ]),
  },

  beforeMount() {
    this.$OneSignal.init({ appId: "5600c594-a4c8-4899-950e-f025a4f5fa78" });
  },

  beforeDestroy() {},

  methods: {
    ...mapActions([
      "fetchProfile",
      "fetchOrdersCount",
      "fetchOrders",
      "fetchOffers",
      "fetchOffersReservedCount",
    ]),
    ...mapMutations(["addToMessages"]),

    play(id) {
      document.getElementById(id).play();
    },

    async initFetchOrdersCount() {
      if (this.orderCountTimeId) {
        clearInterval(this.orderCountTimeId);
      }
      this.orderCountTimeId = setInterval(async () => {
        await this.fetchOrdersCount(this.savedProfile);
      }, 5000);
    },
    async initFetchOffersReservedCount() {
      if (this.offersReservedCountTimeId) {
        clearInterval(this.offersReservedCountTimeId);
      }
      this.offersReservedCountTimeId = setInterval(async () => {
        await this.fetchOffersReservedCount(this.savedProfile);
      }, 5000);
    },
  },

  async mounted() {
    await this.fetchProfile();

    await this.fetchOffers(this.profile);

    await this.fetchOrders(this.profile);

    await this.fetchOrdersCount(this.savedProfile);
    await this.initFetchOrdersCount();

    await this.fetchOffersReservedCount(this.savedProfile);
    await this.initFetchOffersReservedCount();

    let token = localStorage.getItem("token");
    token = token.replace("Bearer ", "");

    socket = new WebSocket(
      "wss://vp.bw2api.ru/api/v1/event/url/ws?token=" + token
    );

    socket.addEventListener("open", function (event) {
      console.log("It's open");
    });

    socket.addEventListener("close", function (event) {
      console.log("It's closed");
    });

    let _this = this;

    socket.addEventListener("message", async function (event) {
      let json = JSON.parse(await event.data.text());

      if (json.message) {
        if (json.message.chat) {
          if (location.pathname !== "/chat-admin") {
            console.log("toast do");

            _this.$bvToast.toast(`У вас новое сообщение от администратора`, {
              title: "Сообщение",
              autoHideDelay: 5000,
              variant: "success",
            });
          }
          _this.addToMessages(json.message);
        }
      }
    });

    this.$OneSignal.setExternalUserId(this.profile.auth);

    this.$OneSignal.on("subscriptionChange", function (data) {
      console.log("subscriptionChange:", data);
    });
    this.$OneSignal.on("permissionPromptDisplay", function (data) {
      console.log("permissionPromptDisplay:", data);
    });
    this.$OneSignal.on("notificationPermissionChange", function (data) {
      console.log("notificationPermissionChange:", data);
    });
    this.$OneSignal.on("popoverShown", function (data) {
      console.log("popoverShown:", data);
    });
    this.$OneSignal.on("customPromptClick", function (data) {
      console.log("customPromptClick:", data);
    });
    this.$OneSignal.on("notificationDisplay", function (data) {
      console.log("notificationDisplay:", data);
    });
    this.$OneSignal.on("notificationDismiss", function (data) {
      console.log("notificationDismiss:", data);
    });

    this.dataLoaded = true;
  },

  watch: {
    allOrdersCount: async function (val, oldVal) {
      if (oldVal != null && oldVal !== val) {
        this.play("audio-new-order");

        await this.fetchOrders(this.savedProfile);
      }
    },
    offersCountReserved: async function (val, oldVal) {
      if (oldVal != null && oldVal !== val) {
        this.play("audio-new-offer");

        await this.fetchOffers(this.savedProfile);
      }
    },
  },
};
</script>
