import Vue from "vue";
import Vuex from "vuex";
import orders from "./modules/orders";
import offers from "./modules/offers";
import profile from "./modules/profile";
import regions from "./modules/regions";
import chat from "./modules/chat";
import authorization from "./modules/authorization";

Vue.use(Vuex);

export default new Vuex.Store({
  actions: {},
  mutations: {},
  state: {},
  getters: {},
  modules: {
    orders,
    profile,
    regions,
    offers,
    authorization,
    chat,
  },
});
