<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<style>
#app img {
  max-width: 100%;
}
</style>

<script>
import BaseLayout from "./layouts/BaseLayout";
import EmptyLayout from "./layouts/EmptyLayout";
import OneSignalVue from "onesignal-vue";

import "./assets/styles/index.scss";

export default {
  computed: {
    layout() {
      return (this.$route.meta.layout || "empty") + "-layout";
    },
  },
  components: {
    EmptyLayout,
    BaseLayout,
  },
  beforeCreate() {
    this.$OneSignal.showSlidedownPrompt();
  },
  beforeDestroy() {
    console.log("beforeDestroy");
    socket.close();
  },
  created: async function () {
    const vm = this;

    this.$http.interceptors.response.use(undefined, function (err) {
      return new Promise(function (resolve, reject) {
        if (
          err.response.status === 401 ||
          err.response.status === 500 ||
          err.response.status === 400
        ) {
          vm.$store.dispatch("logout").then(() => {
            vm.$router.push("/login");
          });
        }
        throw err;
      });
    });
  },
};
</script>
