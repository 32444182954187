<template>
  <div class="mb-4">
    <b-navbar toggleable="lg" type="dark" variant="dark">
      <b-container>
        <b-navbar-brand href="#"> </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item
              :to="link.url"
              active-class="active"
              :exact="link.exact"
              v-for="link in links"
              :key="link.url"
            >
              {{ link.title }}
              <b-badge variant="primary" v-if="link.url == '/'">{{
                allOrdersCount
              }}</b-badge>

              <b-badge variant="success" v-if="link.url == '/offers'"
                >{{ offersCountReserved }}
              </b-badge>
            </b-nav-item>

            <b-nav-item v-if="isLoggedIn" @click="logout"> Выйти </b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-container>
    </b-navbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => ({
    links: [
      { title: "Запросы", url: "/", exact: true },
      { title: "Мои ответы", url: "/offers" },
      { title: "Профиль", url: "/profile" },
      { title: "Написать администратору", url: "/chat-admin" },
      { title: "Чаты", url: "/chats" },
    ],
  }),
  computed: {
    ...mapGetters(["allOrdersCount", "profile", "offersCountReserved"]),
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
  },

  methods: {
    logout: function () {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    },
  },
};
</script>
