export default {
  actions: {
    async fetchOrdersCount(ctx, profile) {
      const params = new URLSearchParams({
        region: profile.region,
        // isLicence: profile.isLegal,
        disabledRegions: profile.filter.disabledRegions.join() || ",",
        disabledTypes: profile.filter.disabledTypes.join() || ",",
        state: "!Ended", // хз что
        itemType: "New",
      });

      const res = await fetch(
        "https://vp.bw2api.ru/api/v1/crud/order/count?" + params.toString()
      );
      const ordersCount = await res.json();

      if (ctx.state.ordersCount == null) {
        ctx.commit("updateOrdersCount", ordersCount);
      } else if (ctx.state.ordersCount !== ordersCount) {
        ctx.commit("updateOrdersCount", ordersCount);
      }
    },

    async fetchOrders(ctx, profile) {
      const params = new URLSearchParams({
        // _limit: 1,
        _offset: 0,
        delay: true, // хз что
        region: profile.region,
        isLicence: profile.isLegal,
        disabledRegions: profile.filter.disabledRegions.join() || ",",
        disabledTypes: profile.filter.disabledTypes.join() || ",",
        state: "!Ended", // хз что
        _populate: "vehicle offers",
        _sort: "-updatedAt",
      });

      const res = await fetch(
        "https://vp.bw2api.ru/api/v1/crud/order?" + params.toString()
      );
      const orders = await res.json();

      ctx.commit("updateOrders", orders);
    },

    async fetchOrder({}, id) {
      const params = new URLSearchParams({
        _id: id,
        _populate: "vehicle offers",
      });

      const res = await fetch(
        "https://vp.bw2api.ru/api/v1/crud/order?" + params.toString()
      );

      return (await res.json())[0];
    },
  },
  mutations: {
    updateOrders(state, orders) {
      state.orders = orders;
    },
    updateOrdersCount(state, count) {
      state.ordersCount = count;
    },
  },
  state: {
    orders: [],
    ordersCount: null,
  },
  getters: {
    allOrders(state) {
      return state.orders;
    },
    allOrdersCount(state) {
      return state.ordersCount;
    },
  },
};
