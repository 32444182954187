import axios from "axios";
import _ from "lodash";

export default {
  actions: {
    async paymentInitiate(ctx, change) {
      try {
        const response = await axios.post(
          "https://vp.bw2api.ru/api/v1/payment/initiate",
          { change: change }
        );
        const payUrl = response.data.confirmation_url;

        return payUrl;
      } catch (error) {
        console.error("Ошибка при пополнении баланса:", error);
      }
    },

    async uploadPresentationVideo(ctx, { video, onSendProgress }) {
      try {
        const response = await axios.post(
          "https://vp.bw2api.ru/api/v1/profile/seller/video",
          { filename: video.name }
        );
        const presignedUrl = response.data.presigned_upload_url;

        axios({
          method: "put",
          url: presignedUrl,
          maxBodyLength: Infinity,
          data: video,
          // onUploadProgress: onSendProgress,
          headers: {
            // "Content-Length": video.size,
            "Content-Type": video.type,
            Accept: "*/*",
            // Connection: "keep-alive",
          },
        }).then(async () => {
          await axios.post(
            "https://vp.bw2api.ru/api/v1/profile/seller/video/complete",
            {}
          );
        });
      } catch (error) {
        console.error("Ошибка при загрузке видео:", error);
      }
    },

    async fetchProfile(ctx) {
      const response = await axios.get("https://vp.bw2api.ru/api/v1/auth_info");

      if (response.status == 200) {
        ctx.commit("updateProfile", response.data.seller);
      }
    },

    async changeProfile(ctx) {
      let response = await axios.patch(
        "https://vp.bw2api.ru/api/v1/crud/seller",
        ctx.state["profile"]
      );

      if (response.status == 200) {
        ctx.commit("updateProfile", response.data);
        return true;
      } else {
        return false;
      }
    },
  },
  mutations: {
    updateProfile(state, profile) {
      state.savedProfile = _.cloneDeep(profile);
      state.profile = profile;
    },
  },
  state: {
    profile: {},
    savedProfile: {},
  },
  getters: {
    profile(state) {
      return state.profile;
    },
    savedProfile(state) {
      return state.savedProfile;
    },
  },
};
